import React from 'react';

import {useGlobalStore} from '../zustandStore.js'
import { Table } from '@mantine/core';

function MetaData() {
    const dataStore = useGlobalStore((state) => state.dataStore);
    const metaData = dataStore?.meta;

    if (!metaData) {
        return <></>
    }
    
    return (
        <Table>
            <Table.Tbody>
                <Table.Tr key="date">
                    <Table.Td>Log creation date:</Table.Td>
                    <Table.Td>{metaData.date}</Table.Td>
                </Table.Tr>
                <Table.Tr key="directory">
                    <Table.Td>Log directory:</Table.Td>
                    <Table.Td>{metaData.directory}</Table.Td>
                </Table.Tr>
                {metaData.sn && 
                <Table.Tr key="directory">
                    <Table.Td>Serial number:</Table.Td>
                    <Table.Td>{metaData.sn}</Table.Td>
                </Table.Tr>
                }
            </Table.Tbody>
        </Table>
    )
}

export default MetaData;
