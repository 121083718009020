import React, { useCallback } from 'react';

import { Tabs, Container } from '@mantine/core';

import '../index.css';

import MetaData from '../charts/MetaData.jsx';
import OmpStateTimeline from "../charts/OmpState.jsx"
import NotificationChart from '../charts/NotificationChart.jsx';
import BatteryOverview from '../charts/BatteryOverview.jsx'
import GpsChart from '../charts/GpsChart.jsx';
import CommunicationChart from '../charts/CommunicationChart.jsx';
import StickInputs from '../charts/StickInputs.jsx';
import BatteryMeta from '../charts/BatteryMetadata.jsx';

import { useNavigate } from 'react-router';


function DiagramTabs() {
    const navigate = useNavigate();

    const checkForClick = useCallback((val) => {
        if (val === "replay"){
            navigate("/replay");
        }
    }, [navigate]);

    return (
        <Container className='diagram-panel' size={"xl"}>
        <Tabs variant="outline" defaultValue="metaData" onChange={checkForClick}>
        <Tabs.List>
            <Tabs.Tab value="metaData">
            Metadata
            </Tabs.Tab>
            <Tabs.Tab value="ompStates">
            State Overview
            </Tabs.Tab>
            <Tabs.Tab value="notification">
            Notifications
            </Tabs.Tab>
            <Tabs.Tab value="battery-overview">
            Battery
            </Tabs.Tab>
            <Tabs.Tab value="battery-meta">
            Battery Meta
            </Tabs.Tab>
            <Tabs.Tab value="gps">
            GNSS
            </Tabs.Tab>
            <Tabs.Tab value="coms">
            Communication
            </Tabs.Tab>
            <Tabs.Tab value="sticks">
            Stick Inputs
            </Tabs.Tab>
            <Tabs.Tab value="replay" ml="auto"  
             style={{backgroundColor: "var(--mantine-color-blue-filled-hover)"}}>
            Go to Replay
            </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="metaData">
            <MetaData/>
        </Tabs.Panel>

        <Tabs.Panel value="ompStates">
            <OmpStateTimeline/>
            <StickInputs/>
        </Tabs.Panel>

        <Tabs.Panel value="notification">
            <NotificationChart/>
        </Tabs.Panel>

        <Tabs.Panel value="battery-overview">
            <BatteryOverview/>
        </Tabs.Panel>
        <Tabs.Panel value="battery-meta">
            <BatteryMeta/>
        </Tabs.Panel>
        <Tabs.Panel value="gps">
            <GpsChart/>
        </Tabs.Panel>
        <Tabs.Panel value="coms">
            <CommunicationChart/>
        </Tabs.Panel>
        <Tabs.Panel value="sticks">
            <StickInputs/>
        </Tabs.Panel>
    </Tabs>
    </Container>
    )
}

export default DiagramTabs;
