import Chart from 'react-apexcharts'
import {useGlobalStore} from '../zustandStore.js'
import { formatTs, getStartAndEnd } from './util';

const notification_code_id = "omp_node_base.omp_data.input.previous_output.oriole_status.notifications.notifications.?.code"

const options = {
  chart: {
    type: 'rangeBar'
  },
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        position: 'top'
      }
    }
  },
  tooltip: {
    custom: function({series, seriesIndex, dataPointIndex, w}) {
      const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
      if (!data) return;

      let tooltip = `${data.x}: ${data.y[0]}s - ${data.y[1]}s`;
      return tooltip;
    }
  },
  xaxis: {
    title: {
      text: 'Timestamp [s]'
    }
  },
}

function NotificationChart() {
    const dataStore = useGlobalStore((state) => state.dataStore);

    const enumDescriptor = dataStore?.enums;
    const notifications = dataStore?.notifications?.notifications;
    if (!enumDescriptor || !notifications) {
        return <div>Loading data...</div>;
    }

    const limits = getStartAndEnd(dataStore)
    options.xaxis = {...options.xaxis, ...limits}

    const data = [];

    for (const [,notification] of Object.entries(notifications)) {
        let entry = {
            x: enumDescriptor[notification_code_id][notification.code],
            y: [formatTs(notification.start), formatTs(notification.end)],
        }
        data.push(entry)
    }
    const series = [{data: data}]

    return (
      <Chart options={options} series={series} type="rangeBar" height={600}  />
    )
}

export default NotificationChart;
