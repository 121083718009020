import { useCallback, Fragment, useState } from "react";
import { useThree } from '@react-three/fiber'
import {useGlobalStore} from './zustandStore.js'
import { Line, Html, Sphere } from "@react-three/drei";
import { useControls, button, folder} from 'leva'
import { useEventListener } from "./hooks/useEventListener.jsx";

function DistanceMeasurement() {
    const raycaster = useThree(state => state.raycaster);
    const mouse = useThree(state => state.mouse);
    const camera = useThree(state => state.camera);

    const pointsRef = useGlobalStore(state => state.points);

    const droneRef = useGlobalStore(state => state.drone);

    const [lines, setLines] = useState([]);

    const [, setter, getter]= useControls(() => ({
        "Measurement": folder({
            ctrlDown: {
            label: "Enable",
            value: false,
            transient: false
        }
    }, {collapsed: true, order: 2}
    )}));

    const setCtrlDown = useCallback((val) => {
        setter({ctrlDown: val});
    }, [setter]);

    useControls(() => ({
        "Measurement": folder({
            "Clear Measurements": button(() => {
                setLines([]);
            })
    })}), [pointsRef, lines, setLines]);

    const onCtrlDown = useCallback((event) => {
        if (event.key === 'Control') {
            setCtrlDown(true);
        }
    }, [setCtrlDown]);

    const onCtrlUp = useCallback((event) => {
        if (event.key === 'Control') {
            setCtrlDown(() => false);

            // remove unfinished line
            if (lines.length > 0 && !lines[lines.length-1].finished) {
                const line_cp = [...lines];
                line_cp.pop();
                setLines(line_cp);
            }
        }
    }, [setCtrlDown, lines, setLines]);

    const onClick = useCallback((event) => {
        if (!getter("ctrlDown")) return;

        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        raycaster.setFromCamera( mouse, camera );
        raycaster.params.Points.threshold = 0.1

        const intersects = raycaster.intersectObjects([pointsRef.current, droneRef.current]);

        if (intersects.length > 0) {
            if (lines.length === 0 || lines[lines.length - 1].finished) {
                const line_points = [intersects[0].point, intersects[0].point.clone()];
                const new_line = {
                    points: line_points,
                    finished: false,
                    point_idx: [intersects[0].index],
                };
                setLines([...lines, new_line]);
            } else {
                if (lines[lines.length -1].finished) return;
                const current_lines = [...lines];
                current_lines[lines.length - 1].points[1] = intersects[0].point;
                current_lines[lines.length - 1].finished = true;
                current_lines[lines.length - 1].point_idx.push(intersects[0].index);
                setLines(current_lines);
            }
        }

    }, [camera, mouse, raycaster, pointsRef, droneRef, getter, lines]);

    const onMouseMove = useCallback((event) => {
        if (!getter("ctrlDown")) return;
        if (lines.length === 0 || lines[lines.length-1].finished) return;

        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        raycaster.params.Points.threshold = 0.1
        raycaster.setFromCamera( mouse, camera );
        const intersects = raycaster.intersectObjects([pointsRef.current, droneRef.current]);
        if (intersects.length > 0) {
            const current_lines = [...lines];
            current_lines[lines.length - 1].points[1] = intersects[0].point;
            setLines(current_lines);
        }

    }, [mouse,raycaster, camera, pointsRef, droneRef, lines, setLines, getter]);

    useEventListener(window, 'pointerdown', onClick);
    useEventListener(window, 'mousemove', onMouseMove);
    useEventListener(window, 'keydown', onCtrlDown);
    useEventListener(window, 'keyup', onCtrlUp);

    const mapped_lines = lines.map((obj, idx)=> {
        const v0 = obj.points[0].clone();
        const v1 = obj.points[1];
        const distance = v0.distanceTo(v1).toFixed(2);
        return (
            <Fragment key={idx}>
                <Sphere position={v0.clone()} args={[0.05]} material-color="#003366"/>
                <Sphere position={v1.clone()} args={[0.05]} material-color="#003366"/>
                <Line points={[...obj.points]} color="blue" lineWidth={5} segments/>
                <Html 
                sprite 
                prepend
                position={v0.lerp(v1,0.5)}
                >
                    <span className="distanceMeasurement">{`${distance}m`}</span>
                </Html>
            </Fragment>
        );
    });

    return (
        <>
            {mapped_lines}
        </>
    )
}

export default DistanceMeasurement;