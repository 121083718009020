import './index.css';
import RunSimulation from './Simulation';
import {StatusBar} from "./StatusBar.jsx"
import { Notifications } from './DroneEvents';
import { JoyStick } from './Joysticks';
import { PickFlightPage } from './pages/PickFlight.jsx';
import { BrowserRouter as Router, Routes, Route, useLocation , useNavigate} from 'react-router-dom';
import { useEffect } from 'react';
import DiagramTabs from './pages/Diagrams.jsx'
import PushMessage from './pages/PushMessage.jsx';
import {useGlobalStore} from './zustandStore.js'

function RedirectIfNoData() {
  const dataStore = useGlobalStore((state) => state.dataStore);

  const navigate = useNavigate();  

  useEffect(() => {
    if (!dataStore) {
      console.log("redirecting")
      navigate("/")
    }
  }, [dataStore, navigate])
  return (
  <>
  </>
  )
}

function OverviewPage() {
  return (  
  <>
    <RedirectIfNoData/>
    <DiagramTabs/>
  </>
  )
}

function MainPage() {
  return (
    <>
      <RedirectIfNoData/>
      <RunSimulation/>
      <JoyStick/>
      <StatusBar/>
      <Notifications/>
    </>
  )
}

function NoMatch() {
  const location = useLocation();
  return (
    <div style={{ padding: 20, backgroundColor: "#a8692a" }}>
      <h2>404: Page Not Found</h2>
      <p>route: {location.pathname}</p>
      <p> "Ask, and it will be given to you; seek, and you will find; knock, and it will be opened to you."</p>
      <p>Matthew 7:7</p>
    </div>
  );
}

function App() {
  return (
    <div className="App">
          <Router>
            <Routes>
                <Route index path="/" element={<PickFlightPage />} />
                <Route path="/replay" element={<MainPage />} />
                <Route path="/overview" element={<OverviewPage />} />
                <Route path="/pushMessage" element={<PushMessage/>} />
                <Route path="*" element={<NoMatch />} />
            </Routes>  
          </Router>
    </div>
  );
}

export default App;
