import React, { useMemo, useCallback } from 'react';
import { Canvas } from '@react-three/fiber'
import { Stats, Points, Line, Html, Grid } from "@react-three/drei";
import { Vector3, TextureLoader, WebGLRenderer, Euler, Quaternion } from 'three'

// Controls
import CreateCameraControls from './CameraControls.jsx';

// ColladaModel
import { useLoader } from '@react-three/fiber';

// Gui
import { useControls, folder, button } from 'leva'

import { FaMapMarkerAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router';

import point_marker from "./assets/point_low_res.png"

import { OrioleModel } from './OrioleModel.jsx';

import { saveAs } from 'file-saver';
import {useGlobalStore} from './zustandStore.js'
import DistanceMeasurement from './DistanceMeasurement.jsx';

function CoordinateAxis() {
    const axisLength = 5;

    const x = new Vector3(1, 0, 0);
    const y = new Vector3(0, 1, 0);
    const z = new Vector3(0, 0, 1);
    return (
        <>
            <arrowHelper args={[x, new Vector3(), axisLength, "red"]} />
            <arrowHelper args={[y, new Vector3(), axisLength, "green"]} />
            <arrowHelper args={[z, new Vector3(), axisLength, "blue"]} />
        </>
    );
}

function GlobalMap() {
    const pointTexture = useLoader(TextureLoader, point_marker);

    const mapVisible = useGlobalStore((state) => state.mapVisible);
    const setMapVisibility = useGlobalStore((state) => state.setMapVisibility);
    const pointsRef = useGlobalStore((state) => state.points);
    const dataStore = useGlobalStore((state) => state.dataStore);

    const main_map = dataStore?.main_map?.data;

    const verticArray = useMemo(() => {
        if (!main_map) return null

        const vertices = [];
        for (const [x,y,z] of main_map) {
            vertices.push(x, y, z);
        }
        console.log("Parsing points")
        return new Float32Array(vertices)
    }, [main_map]);

    // can make the pointcloud invisible, good for finding the drone
    useControls({
        "Settings": folder({
        map_visible: {
            value: mapVisible,
            onChange: value => setMapVisibility(value),
        }
    }, {collapsed: true}
    )});

    if (!verticArray) return <></>

    return (
        <Points ref={pointsRef} positions={verticArray} renderOrder={0} 
        >
         <pointsMaterial
                attach="material"
                size={0.15}
                color={"white"}
                alphaTest={0.5}
                map={pointTexture}
                visible={mapVisible}
            />
        </Points>
    );
}

function VisualizeFlightTrajectory() {
    const dataStore = useGlobalStore((state) => state.dataStore);
    const traj = dataStore?.trajectory?.data

    const trajectoryPoints = [];
    const ts = [];
    const quaternions = [];
    if (!traj) return <></>

    for (let i = 0; i < traj.length; i++) {
        const [timestamp, x, y, z, qx, qy, qz, qw] = traj[i]

        trajectoryPoints.push(new Vector3(x,y,z))
        quaternions.push(new Quaternion(qx, qy, qz, qw))
        ts.push(timestamp / 1e9)
    }

    return (
        <>
        <OrioleModel ts={ts} trajectoryPoints={trajectoryPoints} quaternions={quaternions}/>
        <FlightTrajectory trajectoryPoints={trajectoryPoints}/>
        </>
    );
}

function FlightTrajectory({trajectoryPoints}) {
    return (
        <Line 
        points={trajectoryPoints}
        color={0xff0000}
        linewidth={5}
        />
    );
}

function VisualizeMissions() {
    const dataStore = useGlobalStore((state) => state.dataStore);
    const goalPointVisible = useGlobalStore((state) => state.goalPointVisible);
    const setGoalPointsVisible = useGlobalStore((state) => state.setGoalPointsVisible);

    const enumDescriptor = dataStore?.enums
    const missionData = dataStore?.mission_segments?.data

    useControls({
        "Settings": folder({
        setpoints_visible: {
            value: goalPointVisible,
            onChange: value => setGoalPointsVisible(value),
        }
    }, {collapsed: true, order: 2}
    )});

    const elems = useMemo(() => {
        if (!missionData || !enumDescriptor) return <></>;
        
        let arr = []
        for (let i = 0; i < missionData.length; ++i) {
            const mission = missionData[i];
            if (!mission || !mission.point) continue;

            const mission_name = enumDescriptor["omp_node_base.omp_data.input.current_mission.type"][mission.type]
            const pos = new Vector3(mission.point.x, mission.point.y, mission.point.z)
            const description = `${mission_name} ${mission.segment_num + 1}/${mission.total_segment}`
            const position_text = `x: ${mission.point.x.toFixed(1)} y: ${mission.point.y.toFixed(1)} z: ${mission.point.z.toFixed(1)}`

            arr.push((
            <Html
            transform
            sprite
            zIndexRange={[2, 0]}
            position={pos}
            key={i}
            >
                <FaMapMarkerAlt className='mission-setpoint-marker' size="30"/>
                <div className='mission-setpoint-description'>
                    {description}<br/>{position_text}
                </div>
            </Html>))
        }
        return arr
    }, [missionData, enumDescriptor])

    return (
    <>
        {goalPointVisible && elems}
    </>
    )
}

function GridWrapper() {
    const gridProps = {
        gridSize: [10, 10],
        cellSize: 1,
        cellThickness: 1,
        cellColor: '#6f6f6f',
        sectionSize: 5,
        sectionThickness: 1,
        sectionColor: '#9d4b4b',
        fadeDistance: 250,
        fadeStrength: 0,
        followCamera: true,
        infiniteGrid: true,
    }

    return (
        <Grid rotation={new Euler(Math.PI / 2,0,0)} {...gridProps} />
    )
}

function NavigateTOverview() {
    const navigate = useNavigate();
    useControls({
        "Navigate": folder({
        "Flight Overview": button(() => navigate("/overview"))
    }, {order: 3}
    )});

    return <></>
}

function NavigateToHomeScreen() {
    const navigate = useNavigate()
    useControls({
        "Navigate": folder({
        HomeScreen: button(() => navigate("/"))
    }, {order: 3}
    )});

    return (
        <>
        </>
    )
}

function DisableNotifications() {
    const notificationsState = useGlobalStore((state) => state.notificationsState);
    const setNotificationState = useGlobalStore((state) => state.setNotificationState);

    useControls({
        "Settings": folder({
        notifications: {
            value: notificationsState,
            onChange: value => setNotificationState(value),
        }
    }, {collapsed: true}
    )});
    return (<></>)
}

function DownloadKml() {
    const dataStore = useGlobalStore((state) => state.dataStore);
    const kml = dataStore?.kml?.data;

    const downloadFile = useCallback(() => {
        // decode base64 string
        const decodedData = atob(kml);

        // Convert the decoded data to a Uint8Array
        const uint8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; ++i) {
            uint8Array[i] = decodedData.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], { type: 'application/octet-stream' });

        // Use FileSaver.js to save the Blob as a file
        saveAs(blob, 'replay.kml');
    }, [kml])

    useControls({
        "Navigate": folder({
        "Download Kml": button(() => downloadFile())
    }, {order: 4}
    )});
    
    return (
        <></>
    )
}

function RunSimulation() {
    return (
        <>
        <DownloadKml/>
        <Canvas 
        camera={{ position: [0, 0, 10], fov: 75, up: [0, 0, 1] }} 
        gl={canvas => new WebGLRenderer({ canvas, antialias: false, powerPreference:"low-power", precision: "lowp" })}
        >
            <color attach="background" args={[0x666699]} />
            <CreateCameraControls />
            <GridWrapper/>
            <GlobalMap />
            <CoordinateAxis/>
            <VisualizeFlightTrajectory/>
            <VisualizeMissions/>
            <Stats/>
            <NavigateTOverview/>
            <NavigateToHomeScreen/>
            <DisableNotifications/>
            <DistanceMeasurement/>
        </Canvas>
        </>
    );
}

export default RunSimulation;
