import React, { useCallback, useState} from 'react';
import {useGlobalStore} from '../zustandStore.js'
import Chart from 'react-apexcharts'
import { getStartAndEnd, roundValue } from './util';
import { Accordion, Table } from '@mantine/core';

const options = {
    chart: {
        type: 'rangeBar',
        events: {
        }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        const data = w.globals.initialSeries[seriesIndex]?.data[dataPointIndex].meta;
        if (!data) return;

        let table_str = "";
        for (const [key, val] of Object.entries(data)) {
            table_str += `<tr><th>${key}</th><th>${val}</th></tr>`;
        }

        return `<table>${table_str}</table>`;
      },
    },
    xaxis: {
      title: {
        text: 'Timestamp [s]'
      },
    },
    yaxis: {
        labels: {
          minWidth: 130,
          maxWidth: 130,
        }
    },
    title: {
        text: "Communication Channels",
        align: "center"
    },
};

function buildSasMap(sasData) {
    const sasGraph = new Map();
    for (const entry of sasData) {
        const parsed = JSON.parse(entry);
        const parent_session = sasGraph.get(parsed.spid);
        const ts = roundValue(parsed.timestamp / 1e9);

        let interface_name;
        switch (parsed.type) {
            case "newsessionpeer":
                sasGraph.set(parsed.spid, 
                    {
                    "session" : {sid: parsed.sid, start: ts, end: null, key: "Session" + parsed.spid},
                    "interfaces" : []
                    });
                break;
            case "destroysessionpeer":
                if (!parent_session) {
                    console.log(`No parent found for elem: `, parsed);
                } else {
                    parent_session.session.end = ts;
                }
                break;
            case "newinterface":
                if (!parent_session) {
                    console.log("No parent found for elem: " ,parsed, " in ", sasGraph);
                } else {
                    const ifc_name = `Interface${parsed.ifaceid}`;
                    parent_session.interfaces[ifc_name] = 
                        {
                            start: ts,
                            end: null,
                            unumpair: parsed.unumpair,
                            sid: parent_session.session.sid,
                            key: ifc_name + parsed.spid
                        };
                }
                break;
            case "removeinterface":
                interface_name = `Interface${parsed.ifaceid}`;
                if (!(interface_name in parent_session?.interfaces)) {
                    console.log("No parent found for elem: " ,parsed, " in ", sasGraph);
                } else {
                    parent_session.interfaces[`Interface${parsed.ifaceid}`].end = ts;
                }
                break;
            case "rotate":
            case "statedumpdone":
                break;
            default:
                console.log("Unknown type");
        }
    }

    return sasGraph;
}

function TableRow({title, val}) {
    return (
        <Table.Tr key={title}>
            <Table.Td>{title}</Table.Td>
            <Table.Td>{val}</Table.Td>
        </Table.Tr>
    )
}

function FormatInterface({element}) {
    return (
        <Table>
            <Table.Tbody>
                <TableRow title={"Start"} val={element.start}/>
                <TableRow title={"End"} val={element.end}/>
                <TableRow title={"Unumpair"} val={element.unumpair}/>
            </Table.Tbody>
        </Table>
    )
}

function FormatSession({element}) {
    return (
        <Table>
            <Table.Tbody>
                <TableRow title={"Start"} val={element.start}/>
                <TableRow title={"End"} val={element.end}/>
                <TableRow title={"Ssid"} val={element.sid}/>
            </Table.Tbody>
        </Table>
    )
}

function SasAccordion({sasGraph, selection, setAccordionSelect}) {
    const items = [];

    for (const [idx, session] of sasGraph) {
        const session_key = session.session.key;
        const session_val = session.session;
        items.push(
            <Accordion.Item key={session_key} value={session_key}>
                <Accordion.Control>{session_key}</Accordion.Control>
                <Accordion.Panel>
                    <FormatSession element={session_val}/>
                </Accordion.Panel>
            </Accordion.Item>
        );
        
        for (const [key, val] of Object.entries(session.interfaces)) {
            items.push(
                <Accordion.Item key={val.key} value={val.key}>
                    <Accordion.Control>{key}</Accordion.Control>
                    <Accordion.Panel>
                        <FormatInterface element={val}/>
                    </Accordion.Panel>
                </Accordion.Item>
            );
        }
    }
      
    const clickHandler = useCallback((label) => {
        setAccordionSelect(label);
    }, [setAccordionSelect])

    return (
        <Accordion value={selection} variant="contained" radius="xs" onChange={clickHandler}>
          {items}
        </Accordion>
    );
}

function SASChart() {
    const dataStore = useGlobalStore((state) => state.dataStore);

    const [accordionSelect, setAccordionSelect] = useState();

    const mouseSelect = useCallback((event, chartContext, {seriesIndex, dataPointIndex, w}) => {
        const id = w.globals.initialSeries[seriesIndex]?.data[dataPointIndex].meta.key;
        setAccordionSelect(id);
    }, [setAccordionSelect]);

    const enumDescriptor = dataStore?.enums;
    const sasData = dataStore?.sas?.data
    if (!sasData || !enumDescriptor) {
        return <div>No SAS data available.</div>;
    }

    const limits = getStartAndEnd(dataStore);

    const sasGraph = buildSasMap(sasData);

    const add_elem = (key, val, series) => {
        const name = val.sid.split(":");
        name[0] += ':';

        const marker_start = Math.max(val.start, limits.min);
        let marker_end = limits.max; 
        if (val.end) {
            marker_end = val.end;
        }
        const obj = {x: name, y: [marker_start, marker_end], meta: val};

        const parent_elem = series.find(item => item["name"] === key);
        if (parent_elem) {
            parent_elem.data.push(obj);
        } else {
            series.push({name: key, data: [obj]});
        }
    }

    const series = [];
    for (const [, session] of sasGraph) {
        add_elem("Session", session["session"], series);
        for (const [key, val] of Object.entries(session.interfaces)) {
            add_elem(key, val, series);
        }
    }

    options.chart.events.dataPointMouseEnter = mouseSelect;
    return (
        <>
        <Chart options={options} series={series} type="rangeBar" height={300}  />
        <SasAccordion sasGraph={sasGraph} selection={accordionSelect} setAccordionSelect={setAccordionSelect}/>
        </>
    )
}

export default SASChart;
