import { useRef } from "react";
import {findCurrentStep, findNextStepAfterReset} from './helpers'
import 'bootstrap/dist/css/bootstrap.css';

import { ToastContainer, toast } from 'react-toastify';
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center'
import "react-toastify/dist/ReactToastify.css";
import './index.css';
import {useGlobalStore} from './zustandStore.js'

const convertToSec = (nanosec) => {return parseInt(nanosec) / 1e9}
const getToastData = (row) => {return convertToSec(row[0])};

const notification_code_id = "omp_node_base.omp_data.input.previous_output.oriole_status.notifications.notifications.?.code"

function createNotificationDescription(notification, enumDescriptor) {
    if (!enumDescriptor) {
        return JSON.stringify(notification)
    }

    const detail = (notification.detail) ? `<br>Details: ${notification.detail}` : ""
    const message = (
    <div>
        Type: {enumDescriptor[notification_code_id][notification.code]}<br/>
        Duration: {convertToSec(notification.start).toFixed()}-{convertToSec(notification.end).toFixed()}s
        {detail}
    </div>
    )

    return message
}

function DroneEvents() {
    const dataStore = useGlobalStore((state) => state.dataStore);
    const notificationsState = useGlobalStore((state) => state.notificationsState);
    const globalTs = useGlobalStore((state) => state.globalTs);

    const currentStep = useRef(0);
    
    const enumDescriptor = dataStore?.enums
    const stateData = dataStore?.notifications

    const activeNotification = useRef(null);
    if (!activeNotification.current) {
        activeNotification.current = new Set()
    }

    if (!stateData || !enumDescriptor) return <></>;

    if (!notificationsState) {
        toast.dismiss()
        activeNotification.current = new Set()
        return <></>
    }

    if (globalTs.reset) {
        currentStep.current = findNextStepAfterReset(globalTs.ts, stateData.timeline, getToastData);
        toast.dismiss()
        activeNotification.current = new Set()
    } else {
        currentStep.current = findCurrentStep(currentStep.current, globalTs.ts, stateData.timeline, getToastData);
    }

    const ids = stateData.timeline[currentStep.current][1];

    let toast_props = {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    let set_replacement = new Set();

    for (const id of ids) {
        const id_str = id.toString();
        toast_props.toastId = `drone${id}`;

        if (activeNotification.current.has(id)) {
            set_replacement.add(toast_props.toastId)
            continue;
        }
        const notification = stateData.notifications[id_str];

        const description = createNotificationDescription(notification, enumDescriptor)

        switch (stateData.notifications[id_str].severity) {
            case 0:
                toast.info(description, toast_props)
                break
            case 1:
                toast.warn(description, toast_props)
                break
            case 2:
                toast.error(description, toast_props)
                break
            default:
                toast(JSON.stringify(stateData.notifications[id_str]), toast_props)
        }
        set_replacement.add(toast_props.toastId)
    }
    // remove discarded tasts
    for (const key of activeNotification.current) {
        if (!set_replacement.has(key)) {
            toast.dismiss(key)
        }
    }

    activeNotification.current = set_replacement

    return (
        <></>
    )
}

function UserEvents() {
    const dataStore = useGlobalStore((state) => state.dataStore);
    const globalTs = useGlobalStore((state) => state.globalTs);

    const currentStep = useRef(0);
    
    const enumDescriptor = dataStore?.enums
    const stateData = dataStore?.user_events?.data

    const activeNotification = useRef(null);
    if (!activeNotification.current) {
        activeNotification.current = new Set();
    }

    if (!stateData || !enumDescriptor) return <></>;

    if (globalTs.reset) {
        toast.dismiss()
        activeNotification.current = new Set()

        currentStep.current = 0;
        while (currentStep.current < stateData.length && stateData[currentStep.current].ts / 1e9 < globalTs.ts) {
            currentStep.current += 1
        }
    }

    let toast_props = {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    let set_replacement = new Set();
    for (let i = currentStep.current; i < stateData.length; ++i) {
        if ((stateData[i].ts / 1e9 > globalTs.ts)) break;

        toast_props.toastId = `user${i}`;

        if (activeNotification.current.has(toast_props.toastId)) {
            set_replacement.add(toast_props.toastId)
            continue
        }
        
        const description = stateData[i].text
        switch (stateData[i].severity) {
            case 0:
                toast.info(description, toast_props)
                break
            case 1:
                toast.warn(description, toast_props)
                break
            case 2:
                toast.error(description, toast_props)
                break
            default:
                toast(description, toast_props)
        }
        set_replacement.add(toast_props.toastId)
    }
    activeNotification.current = set_replacement
    
    return (
        <></>
    )
}

export function Notifications() {
    return (
        <div className="alert-container overflow-auto">
            <UserEvents/>
            <DroneEvents/>
            <ToastContainer/>
        </div>
    )
}