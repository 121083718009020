import { createRef } from 'react';
import { create } from 'zustand'

const createCameraMovementSlice = (set, get) => ({
    drone: createRef(),
    
    camera: createRef(),

    points: createRef(),

    focusDrone: () => {
        const {drone, camera} = get();

        const {x,y,z} = drone?.current.position;
        camera?.current?.moveTo(x,y,z,true);
    },
})

const createDataStoreSlice = (set) => ({
    globalTs: {ts: 0, reset: false},
    setGlobalTs: (ts, reset) => set({globalTs: {ts: ts, reset: reset}}),
    
    isRunning: false,
    setIsRunning: (newState) => set({isRunning: newState}),

    speed: 1.0,
    setSpeed: (newState) => set({speed: newState}),

    dataStore: null,
    resetDataStore: () => set({dataStore: null}),
    setDataStore: (newState) => set({dataStore: newState}),
})

const createOptionsSlice = (set) => ({
    notificationsState: true,
    setNotificationState: (newState) => set({notificationsState: newState}),
    mapVisible: true,
    setMapVisibility: (newState) => set({mapVisible: newState}),
    goalPointVisible: true,
    setGoalPointsVisible: (newState) => set({goalPointVisible: newState}),
    followDrone: true,
    setFollowDrone: (newState) => set({followDrone: newState}),
})

export const useGlobalStore = create((...a) => ({
    ...createCameraMovementSlice(...a),
    ...createDataStoreSlice(...a),
    ...createCameraMovementSlice(...a),
    ...createOptionsSlice(...a)
}),);
