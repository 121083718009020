import {memo} from 'react';

import { Stage, Layer, Circle } from 'react-konva';
import { useSpring } from '@react-spring/web'
import {  animated } from '@react-spring/konva';

import {useGlobalStore} from './zustandStore.js'

import { useUpdateState, getTsFromArrayData } from "./hooks/useUpdateState.jsx";

import './index.css';
import joystick_img from './assets/joystick_cleared.png'

export function JoyStick() {
  const [width, height] = [300, 300]

  const dataStore = useGlobalStore((state) => state.dataStore);
  const stateData = dataStore?.stick_inputs?.data
  const currentStep = useUpdateState({dataStore: stateData, getTs: getTsFromArrayData});

  if (!stateData) return (<></>)

  return (
    <>
      <MemoizedJoystickLeft width={width} height={height} data={stateData} step={currentStep}/>
      <MemoizedJoystickRight width={width} height={height} data={stateData} step={currentStep}/>
    </>
  )};

const JoystickLeft = (props)=> {
  console.log("JoystickLeft")
  const {width, height, data, step} = props;
  const img = new window.Image();
  img.src = joystick_img
  const activeWidthOffset = 20;
  const deactivatedWidthOffset = 50;
  const circle_scale = 0.75

  const flag = !data[step][1]
  let circle = (<></>)
  if (data[step][2].length) {
    const yaw_in = data[step][2][0]
    const mid_w = activeWidthOffset + width / 2
    const yaw = mid_w - yaw_in * width / 2 * circle_scale

    const thrust_in = data[step][2][1]
    const mid_h = height / 2
    const thrust = mid_h - thrust_in * mid_h * circle_scale
    
    circle = (
        <Circle x={yaw} y={thrust} radius={30} fill='red' opacity={1}/>
    )
  }

  // for the slide out animation
  const { x } = useSpring({
    x: flag ? -width + deactivatedWidthOffset : activeWidthOffset,
    config: { duration: 200 } 
  })

  return (
    <Stage 
    width={width + activeWidthOffset} 
    height={height} 
    title="Left Joystick"
    className='joystick-left' 
    >
      <Layer>
          <animated.Image image={img} width={width} height={height} x={x} y={0} opacity={0.5}/>
          {circle}
      </Layer>

    </Stage>
  )
}

const MemoizedJoystickLeft = memo(
  JoystickLeft,
  (prevProps, nextProps) => {
    return prevProps.step === nextProps.step
  }
);

function JoystickRight({width, height, data, step}) {
  const img = new window.Image();
  img.src = joystick_img

  // the positioning is from the left side of the canvas
  const activeWidthOffset = 20;
  const deactivatedWidthOffset = 50;
  const circle_scale = 0.75

  let circle = (<></>)
  if (data[step][2].length) {
    const thrust_in = data[step][2][3]
    const mid_h = height / 2
    const pitch = mid_h - thrust_in * mid_h * circle_scale

    const roll_in = data[step][2][2]
    const mid_w = width / 2
    const roll = mid_w - roll_in * width / 2 * circle_scale
    
    circle = <Circle x={roll} y={pitch} radius={30} fill='red' opacity={1}/>
  }

  const flag = !data[step][1]
  const { x } = useSpring({
    x: flag ?  width - deactivatedWidthOffset + activeWidthOffset : 0,
    config: { duration: 200 } 
  })

  return (
    <Stage 
    width={width + activeWidthOffset} 
    height={height} 
    title='Right joystick' 
    className='joystick-right' 
    >
      <Layer>
          <animated.Image image={img} width={width} height={height} x={x} y={0} opacity={0.5}/>
          {circle}
      </Layer>

    </Stage>
  )
}

const MemoizedJoystickRight = memo(
  JoystickRight,
  (prevProps, nextProps) =>  prevProps.step === nextProps.step  
);
