// Rounds value to 2 digits
export function roundValue(val) {
    val = Math.max(val, 0);
    return Math.round(val * 100) / 100;
}

export function formatTs(val) {
    return (val / 1e9).toFixed(2);
}

// Adds new element to chart
export function addElem(chart, array, label) {
    // to make sure we have it even when empty
    if (array.length === 0) {
        chart.push({
            x: label,
            y: []
        });
    }

    for (const val of array) {
        if (val.type) {
            chart.push({
                x: label,
                y: [val.start, val.end]
            });
        }
    }
}

// Gets the start and end of the flight
export function getStartAndEnd(dataStore) {
    const traj = dataStore?.trajectory?.data
    if (traj) {
        return {
            min: roundValue(traj.at(0)[0] / 1e9),
            max: roundValue(traj.at(-1)[0] / 1e9)
        }
    }
}