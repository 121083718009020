import { useEffect, useState } from "react";
import {findCurrentStep, findNextStepAfterReset} from '../helpers'
import {useGlobalStore} from '../zustandStore.js'

export const getTsFromArrayData = (row) => {return row[0] / 1e9};

export function useUpdateState({dataStore, getTs}) {
  const globalTs = useGlobalStore((state) => state.globalTs);
    const [currentStep, setCurrentStep] = useState(0);
    
    useEffect(() => {
      if (!dataStore) {
        return;
      }
  
      if (globalTs.reset) {
        setCurrentStep(findNextStepAfterReset(globalTs.ts, dataStore, getTs));
      } else {
        setCurrentStep(findCurrentStep(currentStep, globalTs.ts, dataStore, getTs));
      }
    }, [globalTs, dataStore, currentStep, getTs]);
  
    return currentStep;
  }
  