import React, { useState, useCallback }  from "react";
import {useGlobalStore} from '../zustandStore.js'
import Chart from 'react-apexcharts';
import { formatTs, getStartAndEnd } from './util';
import { Accordion, Code } from '@mantine/core';

const options = {
    chart: {
        type: 'rangeBar',
        id : 'state',
        events: {
        }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        if (!data) return;

        let tooltip = `${data.x}: ${data.y[0]}s - ${data.y[1]}s`;
        return tooltip;
      }
    },
    xaxis: {
      title: {
        text: 'Timestamp [s]'
      }
    },
    yaxis: {
        labels: {
          minWidth: 100,
          maxWidth: 100,
        }
    },
};

function MetaChart({batteryMetaData, limits}) {
    options.xaxis = {...options.xaxis, ...limits}

    const series_meta = [{data: []}]
    for (const [serial_num, status] of Object.entries(batteryMetaData)) {
        series_meta[0].data.push({
            x: serial_num,
            y: [formatTs(status.start), formatTs(status.end)]
        })
    }

    return (<>
        <Chart options={{...options, title: {text: "Battery Serial", align: "center"}}} series={series_meta} type="rangeBar" height={200}  />
    </>)
}

function MetaAccordion({batteryMetaData, accordionSelect, setAccordionSelect}) {
    const items = [];
    for (const [serial_num, status] of Object.entries(batteryMetaData)) {
        items.push(
            <Accordion.Item key={serial_num} value={serial_num}>
                <Accordion.Control>{serial_num}</Accordion.Control>
                <Accordion.Panel>
                    <Code block>{JSON.stringify(status, null, 2)}</Code>
                </Accordion.Panel>
            </Accordion.Item>
        )
    }

    const clickHandler = useCallback((label) => {
        setAccordionSelect(label);
    }, [setAccordionSelect])
    
    return (
        <Accordion value={accordionSelect} variant="contained" radius="xs" onChange={clickHandler}>
          {items}
        </Accordion>
    )
}

function BatteryMeta() {
    const [accordionSelect, setAccordionSelect] = useState();
    const mouseSelect = useCallback((event, chartContext, {seriesIndex, dataPointIndex, w}) => {
        const serial_num = w.globals.initialSeries[seriesIndex]?.data[dataPointIndex].x;
        setAccordionSelect(serial_num);
    }, [setAccordionSelect]);


    const dataStore = useGlobalStore((state) => state.dataStore);
    const batteryMetaData = dataStore?.battery_meta;
    if  (!batteryMetaData) return <>Battery Metadata Not found</>;

    const limits = getStartAndEnd(dataStore)
    options.chart.events.dataPointMouseEnter = mouseSelect;
    return (
        <>
            <MetaChart batteryMetaData={batteryMetaData} limits={limits}/>
            <MetaAccordion batteryMetaData={batteryMetaData} accordionSelect={accordionSelect} setAccordionSelect={setAccordionSelect}/>
        </>
    )
}

export default BatteryMeta;
