import { useEffect } from "react";

export function useEventListener(target, event, onEvent) {
    useEffect(() => {
        target.addEventListener(event, onEvent, false);
        return () => {
            target.removeEventListener(event, onEvent);
        }
    }, [target, event, onEvent]);
}
  