// getter to find ts inside data array
const default_getter = (input) => {return input;}

// after reset performs binary search
export function findNextStepAfterReset(newTimeSec, data, getter = default_getter) {
    if (!data) {
        console.error("received empty array");
        return 0;
    }

    let counter = 0;
    let left = 0, right = data.length - 1;
    while (left < right) {
        if (counter > 50) {
            console.log("infinite loop");
            break;
        }
        counter += 1


        const mid = left + Math.floor((right - left + 1) / 2);
        const mid_val = getter(data[mid]);
        if (mid_val > newTimeSec) {
            right = mid - 1;
        } else {
            left = mid;
        }
    }

    return left;
}

// linearly finds closest higher element
export function findNextStep(currentStep, nextTimeSec, data, getter = default_getter) {
    if (!data) {
        console.error("received empty array");
        return currentStep;
    }

    let next_frame = currentStep;
    while (next_frame < data.length && getter(data[next_frame]) < nextTimeSec) {
        next_frame++;
    }

    // we came to the end of the data
    if (next_frame === data.length) {
        return null;
    }
    return next_frame;
}

// find the lower times
export function findCurrentStep(currentStep, nextTimeSec, data, getter = default_getter) {
    if (!data) {
        console.error("received empty array");
        return currentStep;
    }

    let next_frame = currentStep;
    while (next_frame + 1 < data.length && getter(data[next_frame + 1]) < nextTimeSec) {
        next_frame++;
    }

    // we came to the end of the data
    if (next_frame === data.length) {
        return null;
    }
    return next_frame;
}

export class Interpolator {
    constructor(ts, trajectoryPoints, quaternions) {
        this.current_frame = 0;  // current_frame chosen from the timestamps
        this.current_time = ts[0];  // float value, higher resolution than the timestamps

        this.ts = ts;  // vector of timestamps
        this.poses = trajectoryPoints;  // vector of positions
        this.quaternions = quaternions;  // encodes the orientations

        console.log("Constructing interpolator")
    }

    setTime(newTime, droneModel) {
        let step = findNextStepAfterReset(newTime, this.ts);
        this.current_frame = step;
        this.current_time = this.ts[step];

        // this moves the drone to next major step, we will refine the position in step(...)
        droneModel.position.copy(this.poses[step]);
        droneModel.setRotationFromQuaternion(this.quaternions[step]);

        // fine tune the position
        this.step(newTime, droneModel);
    }

    getTime() {
        return this.current_time;
    }

    step(next_time, droneModel) {
        if (!droneModel) return;

        const next_frame = findNextStep(this.current_frame, next_time, this.ts);
        if (next_frame === null) {
            return false;
        }

        if (next_frame === this.current_frame) {
            console.warn(`Same time ${next_time}, ${this.current_frame}`);
            return true;
        }

        const portion = (next_time - this.current_time) /
            (this.ts[next_frame] - this.current_time);

        // linear interpolation
        droneModel.position.lerp(this.poses[next_frame], portion);
        droneModel.quaternion.slerp(this.quaternions[next_frame], portion)

        this.current_frame = next_frame - 1;
        this.current_time = next_time;

        return true;
    }

    // advances the drone timeline by duration
    advance(duration, droneModel) {
        return this.step(this.current_time + duration, droneModel)
    }
}
