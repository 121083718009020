import { useCallback } from 'react';
import { decompressSync, strFromU8} from 'fflate';
import enumDescriptor from '../assets/omp_node.enums.json'
import {useGlobalStore} from '../zustandStore.js'


function decompressReplay(compressedContents) {
  const compressedIn = new Uint8Array(compressedContents);
  const unzipContents = strFromU8(decompressSync(compressedIn));
  return JSON.parse(unzipContents);
}

export async function loadPickedFile(selectedFile, abortSignal) {
  return new Promise((resolve, reject) => {
    if (abortSignal) {
      abortSignal.onabort = () => reader.abort();
    }

    const reader = new FileReader();
    reader.onload = function (e) {
      resolve(e.target.result)
    };
    reader.onerror = reject
    reader.readAsArrayBuffer(selectedFile)
  })
}

export async function loadFileFromPublic(selectedFile, abortSignal) {
    return fetch(process.env.PUBLIC_URL + "/" + selectedFile, {singal: abortSignal})
    .then(response => response.arrayBuffer())
}

export function useLoadData() {
  const setDataStore = useGlobalStore((state) => state.setDataStore);
  const setGlobalTs = useGlobalStore((state) => state.setGlobalTs);

  const loadData = useCallback((compressedData) => {
    try {
      const result = decompressReplay(compressedData);

      console.log(`Main map size: ${result?.main_map?.data?.length} points`);

      if (!result.enums) {
        result.enums = enumDescriptor
        console.warn("No enums descriptor found in replay, falling back to default descriptor!")
      }
      setDataStore(result);

      const first_ts = result?.trajectory?.data?.[0]?.[0] / 1e9 ?? 0;

      setGlobalTs(first_ts, true);
    } catch (error) {
      alert(`Failed to load data: ${error}`, compressedData);
    }
  }, [setDataStore, setGlobalTs]);

  return loadData;
}
