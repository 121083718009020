import React from 'react';
import {useGlobalStore} from '../zustandStore.js'
import Chart from 'react-apexcharts'
import { formatTs, getStartAndEnd } from './util';

const options = {
    chart: {
        height: 200,
        type: 'rangeBar',
        group: 'sticks'
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    xaxis: {
      title: {
        text: 'Timestamp [s]'
      }
    },
    yaxis: {
        labels: {
            minWidth: 140,
            maxWidth: 140
        }
    },
    title: {
        text: "Stick Inputs",
        align: "center"
    }
};

function StickInputs() {
    const dataStore = useGlobalStore((state) => state.dataStore);
    const stickData = dataStore?.stick_inputs?.data
    if (!stickData) {
        return <div>Loading data...</div>;
    }

    const limits = getStartAndEnd(dataStore)
    options.xaxis = {...options.xaxis, ...limits}

    const sticks_active = [];
    for (let i = 0; i < stickData.length; i++) {
        const [ts, enabled, values] = stickData[i];
        const ts_sec = formatTs(ts);

        const last_elem  = (i === stickData.length - 1);
        const ts_end = (last_elem) ? limits.max : ts_sec;

        if (enabled && (i === 0 || !stickData[i - 1][1])) {
            sticks_active.push({start: ts_sec, end: ts_sec});
        }

        const next_disabled = last_elem || !stickData[i + 1][1];
        if (enabled && (next_disabled || last_elem)) {
            sticks_active.at(-1).end = ts_end;
        }
    }

    const series = [{data: []}]
    for (const {start, end} of sticks_active) {
        let entry = {
            x: "Stick Active",
            y: [start, end],
        }
        series[0].data.push(entry);
    }

    // if we have no stick inputs we still want to show that there is a state
    if (series[0].data.length === 0) {
        series[0].data.push({x: "Stick Active"});
    }

    return (
        <>
        <Chart options={options} series={series} type="rangeBar" height={200}  />
        </>
    )
}

export default StickInputs;
