import React from 'react';
import {useGlobalStore} from '../zustandStore.js'
import Chart from 'react-apexcharts'

function roundValue(val) {
    val = Math.max(val, 0);
    return Math.round(val * 100) / 100;
}

function BatteryOverview() {
    const dataStore = useGlobalStore((state) => state.dataStore);
    
    const batteryData = dataStore?.battery?.data
    if (!batteryData) {
        return <div>Loading data...</div>;
    }

    
    const series_prc = [
        {name: "Battery Percentage [%]", data: []},
        {name: "Available Flight Percentage [%]", data: []},
        {name: "Return to Home Reserve [%]", data: []},
        {name: "Emergency Landing Reserve [%]", data: []}
    ];

    const series_time = [
        {name: "Remaining Flight Time [s]", data: []}
    ];

    const series_voltage = [
        {name: "Cell 0 [V]", data: []},
        {name: "Cell 1 [V]", data: []},
        {name: "Cell 2 [V]", data: []},
        {name: "Cell 3 [V]", data: []},
    ];

    const series_current = [
      {name: "Current [A]", data: []},
  ];

    let decimator = 0;
    for (const row of batteryData) {
        decimator += 1;
        if (decimator % 10 === 0 && batteryData.length > 1000) {
            continue;
        }
        const [ts, red_percent, yellow_percent, green_percent, battery_percent,
            battery_sec, cell0, cell1, cell2, cell3, current] = row;
        
        const ts_parsed = roundValue(ts / 1e9);
        series_prc[0].data.push([ts_parsed, roundValue(battery_percent)]);
        series_prc[1].data.push([ts_parsed, roundValue(green_percent)]);
        series_prc[2].data.push([ts_parsed, roundValue(yellow_percent)]);
        series_prc[3].data.push([ts_parsed, roundValue(red_percent)]);

        series_time[0].data.push([ts_parsed, roundValue(battery_sec)]);

        series_voltage[0].data.push([ts_parsed, roundValue(cell0)]);
        series_voltage[1].data.push([ts_parsed, roundValue(cell1)]);
        series_voltage[2].data.push([ts_parsed, roundValue(cell2)]);
        series_voltage[3].data.push([ts_parsed, roundValue(cell3)]);

        if (current) {
          series_current[0].data.push([ts_parsed, roundValue(current)]);
        }
    }
    
    const options_prc = {
      chart: {
        type: 'line',
        id: "prc",
        group: "battery",
      },
      xaxis: {
        type: 'numeric',
        title: {
            text: 'Timestamp [s]'
          }
      },
      yaxis: {
        type: 'numeric',
        title: {
            text: 'Remaining Percentage [%]'
          }
      },
      title: {
        text: 'Battery Charge Percentages',
        align: 'center'
      },
    }

    const options_time = {
        chart: {
          type: 'line',
          id: "time",
          group: "battery",
        },
        xaxis: {
          type: 'numeric',
          title: {
              text: 'Timestamp [s]'
            }
        },
        yaxis: {
          type: 'numeric',
          title: {
              text: 'Remaining Time [s]'
            }
        },
        title: {
          text: 'Available Flight Time',
          align: 'center'
        },
      }

      const options_voltage = {
        chart: {
          type: 'line',
          id: "voltage",
          group: "battery",
        },
        xaxis: {
          type: 'numeric',
          title: {
              text: 'Timestamp [s]'
            }
        },
        yaxis: {
          type: 'numeric',
          title: {
              text: 'Voltage [V]'
            }
        },
        title: {
          text: 'Cell Voltages',
          align: 'center'
        },
      }

      const options_current = {
        chart: {
          type: 'line',
          id: "current",
          group: "battery",
        },
        xaxis: {
          type: 'numeric',
          title: {
              text: 'Timestamp [s]'
            }
        },
        yaxis: {
          type: 'numeric',
          title: {
              text: 'Current [A]'
            }
        },
        title: {
          text: 'Battery Current',
          align: 'center'
        },
      }

    let height = "250";
    // Older replays might not have this fature
    const hasCurrentData = series_current[0].data.length > 0;
    if (!hasCurrentData) {
      height = "350";
    }
    return (
        <>
        <Chart options={options_prc} series={series_prc} height={height} />
        <Chart options={options_time} series={series_time} height={height}  />
        <Chart options={options_voltage} series={series_voltage} height={height}/>
        {hasCurrentData && <Chart options={options_current} series={series_current} height={height}/>}
      </>
    );
}
  
export default BatteryOverview;