import React from 'react';
import {useGlobalStore} from '../zustandStore.js'
import Chart from 'react-apexcharts'
import { addElem, getStartAndEnd, roundValue } from './util';
import SASChart from './SasChart';

const lte_technology = "omp_node_base.omp_data.input.system_status.communication.lte_technology"

function doMarshal(array, ts, current) {
    if (array.length) {
        array.at(-1).end = ts;
    }
    if (!array.length || array.at(-1).type !== current) {
        array.push({type: current, start: ts, end: ts});
    }
}

const options = {
    chart: {
        type: 'rangeBar',
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        if (!data) return;

        let tooltip = `${data.x}: ${data.y[0]}s - ${data.y[1]}s`;
        return tooltip;
      }
    },
    xaxis: {
      title: {
        text: 'Timestamp [s]'
      }
    },
    yaxis: {
      labels: {
        minWidth: 130,
        maxWidth: 130,
      }
  },
    title: {
        align: "center"
    }
};

function CommunicationChart() {
  const dataStore = useGlobalStore((state) => state.dataStore);

    const enumDescriptor = dataStore?.enums;
    const comData = dataStore?.communication?.data
    if (!comData || !enumDescriptor) {
        return <div>Loading data...</div>;
    }
    const limits = getStartAndEnd(dataStore)
    options.xaxis = {...options.xaxis, ...limits}

    const marshaled = {
        wifi: [],
        lte: [],
        video: [],
        drone_lte: [],
    }

    for (const [ts_raw, wifi, lte, video, drone_lte] of comData) {
        const ts = roundValue(ts_raw / 1e9);

        doMarshal(marshaled.wifi, ts, wifi);
        doMarshal(marshaled.lte, ts, lte);
        doMarshal(marshaled.video, ts, video);
        
        doMarshal(marshaled.drone_lte, ts, enumDescriptor[lte_technology][drone_lte]);
    }
    // add the final points
    if (comData.length) {
      doMarshal(marshaled.wifi, limits.max, marshaled.wifi.at(-1).type);
      doMarshal(marshaled.lte, limits.max, marshaled.lte.at(-1).type);
      doMarshal(marshaled.video, limits.max, marshaled.video.at(-1).type);
      doMarshal(marshaled.drone_lte, limits.max, marshaled.drone_lte.at(-1).type);  
    }

    const series = [{data: []}]
    addElem(series[0].data, marshaled.wifi, "WIFI (CH1)");
    addElem(series[0].data, marshaled.lte, "LTE (CH2)");
    addElem(series[0].data, marshaled.video, "VIDEO (CH3)");

    const lte_state = [];
    for (let {type, start, end} of marshaled.drone_lte) {
        if (type === "TECH_UNSPECIFIED") {
            type = "No connection";
        }
        lte_state.push({x: type, y: [start, end]})
    }

    const channel_options = options;
    channel_options.chart.id = "channels";
    channel_options.title.text = "Communication Channels";

    const lte_options = options;
    lte_options.chart.id = "lte";
    lte_options.title.text = "Drone LTE State";

    return (
        <>
            <Chart options={channel_options} series={series} type="rangeBar" height={300}  />
            <Chart options={lte_options} series={[{data: lte_state}]} type="rangeBar" 
            height={lte_state.length * 100 + 100}  />
            <SASChart/>
        </>
    )
}

export default CommunicationChart;
