import React from 'react';
import {useGlobalStore} from '../zustandStore.js'
import Chart from 'react-apexcharts'
import { formatTs, getStartAndEnd } from './util';

const options = {
  chart: {
    type: 'rangeBar',
    group: 'omp_state',
  },
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        position: 'top'
      }
    }
  },
  tooltip: {
    custom: function({series, seriesIndex, dataPointIndex, w}) {
      const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
      if (!data) return;
      
      const lable_type = (data.mission) ? data.mission : data.x;
      let tooltip = `${lable_type}: ${data.y[0]}s - ${data.y[1]}s`;
      return tooltip;
    }
  },
  xaxis: {
    title: {
      text: 'Timestamp [s]'
    }
  },
  yaxis: {
      labels: {
        minWidth: 140,
        maxWidth: 140,
      }
  },
}

function OmpStateTimeline() {
  const dataStore = useGlobalStore((state) => state.dataStore);
    
    const enumDescriptor = dataStore?.enums;
    const fsmState = dataStore?.fsm?.data;
    if (!enumDescriptor || !fsmState) {
        return <div>Loading data...</div>;
    }

    const fsm_enum = "omp_node_base.fsm_state_id";
    const mission_enum = "omp_node_base.omp_data.input.current_mission.type";
    
    const limits = getStartAndEnd(dataStore)
    options.xaxis = {...options.xaxis, ...limits}

    const data = []
    for (let i = 0; i < fsmState.length; i++) {

        const ts_start = formatTs(fsmState[i][0]);
        let ts_end;
        if (i !== fsmState.length - 1) {
          ts_end = formatTs(fsmState[i+1][0]);
        } else {
          ts_end = limits.max;
        }
        let entry = {
            x : enumDescriptor[fsm_enum][fsmState[i][1]], // "Omp state"
            y: [ts_start, ts_end],
            mission: enumDescriptor[mission_enum][fsmState[i][2]]
          };
        data.push(entry);
    }

    const series = [{data: data}]

    return (
      <Chart options={options} series={series} type="rangeBar" height={600}  />
    );
}
  
export default OmpStateTimeline;
