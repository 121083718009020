import { LoadingOverlay } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { useLoadData } from '../hooks/useLoadData';

function PushMessage() {
    const loadData = useLoadData();
    const navigate = useNavigate();  
    
    const dataLoad = useCallback(e => {
      if (e.data.type !== "replay")  // we don't check origin, no security risk here
      {
        return;
      }

      loadData(e.data.replay);
      navigate("/replay");
    }, [navigate, loadData]);
  
    useEffect(() => {
      window.addEventListener("message", dataLoad);
  
      return () => {
        window.removeEventListener("message", dataLoad);
      };
    }, [dataLoad]);
  
    // iframe has window.parent
    // popup has  window.opener
    console.log(window.parent, window.opener)
    const parent = (window.opener) ? window.opener : window.parent;
    if (!parent) {
      console.warn("No parent found!");
    }
    parent.postMessage("Finished loading", "*");
    console.log("loaded pushMessage page")
  
    return <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
}

export default PushMessage;
